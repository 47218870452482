
import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IUserProfileUpdate } from '@/interfaces';
import { appName } from '@/env';
import { commitAddNotification } from '@/store/main/mutations';
import {dispatchResetPassword, dispatchVerifyAccount} from '@/store/main/actions';

@Component
export default class UserProfileEdit extends Vue {
  public appName = appName;

  public mounted() {
    this.checkToken();
  }

  public checkToken() {
    const token = (this.$router.currentRoute.query.token as string);
    if (!token) {
      commitAddNotification(this.$store, {
        content: 'No token provided in the URL',
        color: 'error',
      });
    } else {
      this.submit(token);
    }
  }

  public async submit(token) {
    if (token) {
      await dispatchVerifyAccount(this.$store, { token });
    }
  }
}
